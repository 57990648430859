<template>
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 42 42"
        style="enable-background:new 0 0 42 42;"
        xml:space="preserve"
        :width="width"
        :height="height"
    >
        <path
            :fill="color"
            d="M26,17H16c-1.9,0-3.5-1.6-3.5-3.5v-8C12.5,3.6,14.1,2,16,2h10c1.9,0,3.5,1.6,3.5,3.5v8C29.5,15.4,27.9,17,26,17
            z M16,5c-0.3,0-0.5,0.2-0.5,0.5v8c0,0.3,0.2,0.5,0.5,0.5h10c0.3,0,0.5-0.2,0.5-0.5v-8C26.5,5.2,26.3,5,26,5H16z"
        />
        <path
            :fill="color"
            d="M33.5,29c-0.8,0-1.5-0.7-1.5-1.5v-4c0-0.3-0.3-0.5-0.6-0.5H10.6c-0.3,0-0.6,0.2-0.6,0.5v4
            c0,0.8-0.7,1.5-1.5,1.5S7,28.3,7,27.5v-4c0-1.9,1.6-3.5,3.6-3.5h20.8c2,0,3.6,1.6,3.6,3.5v4C35,28.3,34.3,29,33.5,29z"
        />
        <path
            :fill="color"
            d="M13.5,40h-10C1.6,40,0,38.4,0,36.5v-7C0,27.6,1.6,26,3.5,26h10c1.9,0,3.5,1.6,3.5,3.5v7
            C17,38.4,15.4,40,13.5,40z M3.5,29C3.2,29,3,29.2,3,29.5v7C3,36.8,3.2,37,3.5,37h10c0.3,0,0.5-0.2,0.5-0.5v-7c0-0.3-0.2-0.5-0.5-0.5
            H3.5z"
        />
        <path
            :fill="color"
            d="M38.5,40h-10c-1.9,0-3.5-1.6-3.5-3.5v-7c0-1.9,1.6-3.5,3.5-3.5h10c1.9,0,3.5,1.6,3.5,3.5v7
            C42,38.4,40.4,40,38.5,40z M28.5,29c-0.3,0-0.5,0.2-0.5,0.5v7c0,0.3,0.2,0.5,0.5,0.5h10c0.3,0,0.5-0.2,0.5-0.5v-7
            c0-0.3-0.2-0.5-0.5-0.5H28.5z"
        />
        <path
            :fill="color"
            d="M21,22.9L21,22.9c-0.8,0-1.5-0.7-1.5-1.5v-5c0-0.8,0.7-1.5,1.5-1.5h0c0.8,0,1.5,0.7,1.5,1.5v5
            C22.5,22.2,21.8,22.9,21,22.9z"
        />
    </svg>
</template>

<script>
import baseSvgImg from './baseSvgImg';
export default {
    extends: baseSvgImg,
    props: {
        color: {
            default: '#007AFF',
            type: String
        }
    }
};
</script>

<style scoped>
svg {
    display: block;
}
</style>
