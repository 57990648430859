<template>
    <div
        v-if="domainLogin"
        class="login-btn"
        ref="domainLogin"
        @click="login"
        :class="{ loading: loading, openModal: showLoginModal }"
    >
        <span class="entry-input_button-domainLogin">
            <span>{{ $i18n(loginName) }}</span>
            <i @click.stop="clickDomainButton"></i>
        </span>
        <login-model
            class="user-container"
            v-if="showLoginModal"
            :activeIndex="loginType"
            :typeList="loginTypeName"
            :style="loginStyle"
            @clickEvent="changeType"
        ></login-model>
    </div>
    <div v-else class="login-btn" :class="{ loading: loading }" @click="login">
        {{ $i18n('button.login') }}
    </div>
</template>

<script>
export default {
    name: 'camelliaLoginButton',

    props: {
        loading: {
            type: Boolean,
            default: false
        },
        loginAuthMethod: {
            type: String,
            default: '0'
        },
        loginType: {
            type: Number,
            default: 0
        }
    },

    computed: {
        domainLogin() {
            return this.loginAuthMethod === 'systemOrAdDomain' ? true : false;
        },

        loginName() {
            return this.loginTypeName[this.loginType];
        }
    },

    components: { loginModel: () => import('../component/loginModel') },

    data() {
        return {
            showLoginModal: false,
            loginTypeName: ['button.normal', 'button.domain'],
            loginStyle: {}
        };
    },

    methods: {
        login() {
            this.$emit('login');
        },

        clickDomainButton() {
            if (!this.showLoginModal && !this.loginStyle.hasOwnProperty('left')) {
                const { left, bottom, width } = this.$refs.domainLogin.getBoundingClientRect();
                this.loginStyle = {
                    left: left + 'px',
                    top: bottom + 'px',
                    width: width + 'px'
                };
            }
            this.showLoginModal = !this.showLoginModal;
        },

        changeType(index) {
            this.$emit('changeType', index);
            this.clearModal();
        },

        clearModal() {
            this.showLoginModal = false;
        }
    },

    mounted() {
        document.addEventListener('click', this.clearModal);
        window.addEventListener('resize', () => {
            this.clearModal();
            this.loginStyle = {};
        });
    },

    beforeDestroy() {
        document.removeEventListener('click', this.clearModal);
    }
};
</script>

<style scoped lang="scss">
.login-btn {
    width: 264px;
    background-color: #0a4196;
    height: 36px;
    border-radius: 4px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    font-size: 12px;
    cursor: pointer;
    outline: none;
    &.loading {
        background-color: rgba(0, 0, 0, 0.2) !important;
        pointer-events: none;
    }
    .entry-input_button-domainLogin {
        padding: 9px 18px;
        position: relative;
        i {
            content: '';
            position: absolute;
            right: 5px;
            top: 16px;
            border-bottom: 4px solid #fff;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            transition: all 0.3s linear;
            transform-origin: 50% 70%;
        }
    }

    &.openModal {
        border-radius: 4px 4px 0 0;
        i {
            transform: rotateZ(180deg);
        }
    }
    .user-container {
        color: #000;
    }
}

@media screen and (max-width: 420px) {
    .login-btn {
        width: 284px;
        font-size: 16px;
        background-color: #007aff;
    }
}
</style>
