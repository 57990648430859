<template>
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 42 42"
        style="enable-background:new 0 0 42 42;"
        xml:space="preserve"
        :width="width"
        :height="height"
    >
        <path
            :fill="color"
            d="M19,28.5L19,28.5c-0.8,0-1.5-0.7-1.5-1.5v-3c0-0.8,0.7-1.5,1.5-1.5h0c0.8,0,1.5,0.7,1.5,1.5v3
            C20.5,27.8,19.8,28.5,19,28.5z"
        />
        <path
            :fill="color"
            d="M34.5,38h-31C1.6,38,0,36.4,0,34.5v-18C0,14.6,1.6,13,3.5,13h31c1.9,0,3.5,1.6,3.5,3.5v18
            C38,36.4,36.4,38,34.5,38z M3.5,16C3.2,16,3,16.2,3,16.5v18C3,34.8,3.2,35,3.5,35h31c0.3,0,0.5-0.2,0.5-0.5v-18
            c0-0.3-0.2-0.5-0.5-0.5H3.5z"
        />
        <path
            :fill="color"
            d="M28,16c-0.8,0-1.5-0.7-1.5-1.5v-4C26.5,6.4,23.1,3,19,3c-4.1,0-7.5,3.4-7.5,7.5v4c0,0.8-0.7,1.5-1.5,1.5
            s-1.5-0.7-1.5-1.5v-4C8.5,4.7,13.2,0,19,0s10.5,4.7,10.5,10.5v4C29.5,15.3,28.8,16,28,16z"
        />
    </svg>
</template>

<script>
import baseSvgImg from './baseSvgImg';
export default {
    extends: baseSvgImg,
    props: {
        color: {
            default: '#007AFF',
            type: String
        }
    }
};
</script>

<style scoped>
svg {
    display: block;
}
</style>
