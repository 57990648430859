<template>
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 42 42"
        style="enable-background:new 0 0 42 42;"
        xml:space="preserve"
        :width="width"
        :height="height"
    >
        <path
            :fill="color"
            d="M19,3c4,0,7.3,3.3,7.3,7.3S23,17.5,19,17.5s-7.3-3.3-7.3-7.3S15,3,19,3 M19,0C13.3,0,8.7,4.6,8.7,10.2
            S13.3,20.5,19,20.5s10.3-4.6,10.3-10.3S24.7,0,19,0L19,0z"
        />
        <path :fill="color" d="M27.7,21.8" />
        <path :fill="color" d="M26.2,21" />
        <path
            :fill="color"
            d="M1.5,38C0.7,38,0,37.3,0,36.5c0-10.5,8.5-19,19-19c1.8,0,3.6,0.3,5.3,0.8c0.8,0.2,1.3,1.1,1,1.9
            c-0.2,0.8-1.1,1.2-1.9,1c-1.4-0.4-2.9-0.6-4.5-0.6c-8.8,0-16,7.2-16,16C3,37.3,2.3,38,1.5,38z"
        />
        <path
            :fill="color"
            d="M17.5,38h-16C0.7,38,0,37.3,0,36.5S0.7,35,1.5,35h16c0.8,0,1.5,0.7,1.5,1.5S18.3,38,17.5,38z"
        />
        <path
            :fill="color"
            d="M36.5,38H24c-0.8,0-1.5-0.7-1.5-1.5S23.2,35,24,35h12.5c0.8,0,1.5,0.7,1.5,1.5S37.3,38,36.5,38z"
        />
        <path
            :fill="color"
            d="M33.5,32.3H24c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5h9.5c0.8,0,1.5,0.7,1.5,1.5S34.3,32.3,33.5,32.3z"
        />
        <path
            :fill="color"
            d="M30.5,26.7H24c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5h6.5c0.8,0,1.5,0.7,1.5,1.5S31.3,26.7,30.5,26.7z"
        />
    </svg>
</template>

<script>
import baseSvgImg from './baseSvgImg';
export default {
    extends: baseSvgImg,
    props: {
        color: {
            default: '#007AFF',
            type: String
        }
    }
};
</script>

<style scoped>
svg {
    display: block;
}
</style>
