import Base from '@/gikam/js/components/base';
import Gikam from '@/gikam/js/core/gikam-core';
import { I18N } from '@/gikam/i18n/I18N';
import I18NComp from '@/gikam/js/components/I18N';
import Vue from 'vue';
import '../css/home.css';
import '@/gikam/js/core/gikam-global-event';

import homeVue from '../vue/camellia/home';
import homeDeptVue from '../vue/camellia/homeDept';
import homeDeptRoleVue from '../vue/camellia/homeDeptRole';

import arrowDown from '@/gikam/js/components/template/img/arrowDown.vue';
import scroll from '@/gikam/js/components/template/scroll/vue/scroll';

Gikam.ignoreErrorUrl = ['logins/action/check-account'];

Vue.component('arrowDown', arrowDown);
Vue.component('scroll', scroll);

Gikam.component.I18N = I18NComp;

const defaultOptions = {
    //配置的logo标志
    logo: void 0,
    //国际化文件
    I18NUrl: void 0,
    //皮肤主题，选择范围green/blue
    skin: 'blue',
    style: 'camellia',
    //是否选择部门和角色
    selectDeptAndRole: false,
    //配置系统小图标
    xIconUrl: void 0,
    //系统名称
    system: void 0,
    //是否记住登录用户名
    userRemember: false,
    //默认语言
    locale: 'zh-cn',
    //是否显示中英文切换
    i18n: false
};

export default class HomePage extends Base {
    constructor(props) {
        super();
        this.initialize(props, defaultOptions).init();
    }

    async init() {
        if (!localStorage.getItem('locale')) {
            localStorage.setItem('locale', this.options.locale);
        }
        this.locale = localStorage.getItem('locale');
        await I18N.init(this.locale);
        await I18NComp.initMessage(this.locale);
        if (this.options.style === 'camellia') {
            this.model = this.createViewModel();
        } else {
            await this.createNewPage();
        }

        this.initSystemInfo();
    }

    initSystemInfo() {
        document.title = I18NComp.prop(this.options.title);
        if (this.options.xIconUrl) {
            const linkDom = document.createElement('link');
            Gikam.setDomAttribute(linkDom, {
                rel: 'icon',
                href: this.options.xIconUrl
            });
            document.head.appendChild(linkDom);
        }
    }

    createViewModel() {
        const _this = this;
        new Vue({
            data() {
                return _this.options;
            },
            render() {
                let home = this.selectDept ? homeDeptVue : this.selectDeptAndRole ? homeDeptRoleVue : homeVue;

                if (this.loginAuthMethod === 'adDomain' || this.loginAuthMethod === 'systemOrAdDomain') {
                    home = homeVue;
                }

                return (
                    <home
                        logo={this.logo}
                        skin={this.skin}
                        system={I18NComp.prop(this.system)}
                        userRemember={this.userRemember}
                        description={this.description}
                        locale={_this.locale}
                        loginAuthMethod={this.loginAuthMethod}
                        i18n={this.i18n}
                    ></home>
                );
            }
        }).$mount(Gikam.createDom('div', document.body));
    }

    async createNewPage() {
        const getStore = await import('../js/store');
        const style = this.options.style;

        Vue.filter('propI18N', value => Gikam.propI18N(value));
        Vue.directive('bind-background', function(el, bind) {
            if (bind.value) {
                el.style.backgroundImage = `url(${bind.value})`;
            }
        });

        this.newModel = new Vue({
            el: Gikam.createDom('div', document.body),
            store: getStore.default(this.options, this.locale, Vue),
            components: {
                page: () => import(`../vue/${style}/vue/${style}.vue`)
            },
            render() {
                return <page></page>;
            }
        });
    }

    static setConfig(props) {
        Object.assign(defaultOptions, props);
        defaultOptions.style = props.pageStyle;
        defaultOptions.skin = props.pageSkin;
        if (props.loginOrg === '1' && props.loginRole === '1') {
            defaultOptions.selectDeptAndRole = true;
        } else if (props.loginOrg === '1') {
            defaultOptions.selectDept = true;
        }
    }
}
